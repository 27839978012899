<template>
  <div class="actions">
    <router-link
      :to="`/${config.steps.length}`"
      v-if="error"
      class="action action--error sc_button sc_button_default"
      >{{ errorLabel || config.labels.back }}</router-link
    >
    <router-link
      :to="`/${prevStep.index + 1}`"
      v-if="prevStep"
      class="action action--prev"
      >{{ prevStepLabel || config.labels.previousStep }}</router-link
    >
    <button
      type="submit"
      v-if="nextStep"
      class="action action--next sc_button sc_button_default"
    >
      <span class="sc_button_text">{{
        nextStepLabel || config.labels.nextStep
      }}</span>
    </button>
    <button
      type="submit"
      v-if="submit"
      class="action action--submit sc_button sc_button_default"
    >
      <span class="sc_button_text">{{
        submitLabel || config.labels.submit
      }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Actions",
  inject: ["config"],
  props: {
    error: Boolean,
    errorLabel: String,
    prevStep: Object,
    prevStepLabel: String,
    nextStep: Object,
    nextStepLabel: String,
    submit: Boolean,
    submitLabel: String
  }
};
</script>

<style scoped>
.actions {
  display: flex;
  margin-top: 3rem;
}

.action {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.25rem;
  background: #a9c938;
  color: #fff;
  font: inherit;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.action--prev {
  color: inherit;
  background: #f5f7f8;
}
.action--next,
.action--submit {
  margin-left: auto;
}
.action--submit {
  background: #a9c938;
}
.action--error {
  background: #c30;
}
</style>
