<template>
  <form class="form" action="#" @submit="onSubmit">
    <Steps :steps="config.steps" />

    <div v-if="currentStep">
      <h2 class="title">{{ currentStep.title }}</h2>
      <div class="fields">
        <div v-for="field in currentStep.fields" :key="field.name">
          <Field v-bind="field" />
        </div>
      </div>
      <Actions :submit="!nextStep" :nextStep="nextStep" :prevStep="prevStep" />
    </div>
    <div v-else>
      {{ config.labels.stepNotFound }}
      <router-link to="1">{{ config.labels.toOverview }}</router-link>
    </div>
  </form>
</template>

<script>
import DOMPurify from "dompurify";
import Steps from "./Steps.vue";
import Field from "./Field.vue";
import Actions from "./Actions.vue";
import { getFormData } from "../store";

export default {
  name: "Form",
  components: {
    Steps,
    Field,
    Actions
  },
  inject: ["config", "store"],
  computed: {
    currentStep() {
      const step = this.config.steps[this.store.currentStep - 1];

      if (!step) {
        return null;
      }

      return step;
    },
    prevStep() {
      const index = this.store.currentStep - 2;
      const step = this.config.steps[index];

      if (!step) {
        return null;
      }

      return { step, index };
    },
    nextStep() {
      const index = this.store.currentStep;
      const step = this.config.steps[index];

      if (!step) {
        return null;
      }

      return { step, index };
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      // Polyfill
      if (!window.fetch) {
        await import("whatwg-fetch");
      }

      // Show next step instead of submitting
      if (this.nextStep) {
        this.$router.push({ path: `/${this.nextStep.index + 1}` });

        return;
      }

      // Reset store
      this.store.success = null;
      this.store.error = null;
      this.store.errorInternal = null;

      // Filter relevant fields to submit
      const formData = getFormData();

      // Submit
      try {
        const response = await fetch(this.config.api, {
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formData),
          method: "POST"
        });

        const data = await response.json();

        // Sanitize incoming message
        let message = data?.message ? DOMPurify.sanitize(data.message) : "";

        // Add empty lines
        message = message
          .replace(/<br>/g, "<br><br>")
          .replace(/<br><br><br>/g, "<br><br>");

        // Show success or error page
        if (response.ok) {
          this.store.success = message;

          this.$router.push({ path: "/success" });
        } else {
          this.store.error = message;

          this.$router.push({ path: "/error" });
        }
      } catch (err) {
        this.store.errorInternal = `${err.message} (${navigator.userAgent})`;

        this.$router.push({ path: "/error" });
      }
    }
  }
};
</script>

<style scoped>
.title {
  margin-top: 0;
  margin-bottom: 3rem;
  font-size: 1.875rem;
  font-weight: 200;
}

.actions {
  display: flex;
  margin-top: 3rem;
}

.action {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.25rem;
  background: #a9c938;
  color: #fff;
  font: inherit;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.action--prev {
  color: inherit;
  background: #f5f7f8;
}
.action--next,
.action--submit {
  margin-left: auto;
}
.action--submit {
  background: #a9c938;
}
</style>
