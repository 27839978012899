import Vue from "vue";
import config from "./config";

// Extract relevant fields for submission
const uiFields = config.steps.reduce((acc, step) => {
  step.fields.forEach(field => {
    if (field.skipSubmission) {
      acc.push(field.name);
    }
  });

  return acc;
}, []);

// Set up repeatable fields
const fields = config.steps.reduce((acc, step) => {
  step.fields.forEach(field => {
    acc[field.name] = field.fields ? [{}] : null;
  });

  return acc;
}, {});

// Set up store
const store = Vue.observable({
  currentStep: 1,
  fields,
  success: null,
  error: null,
  errorInternal: null
});

// Extract submission from store
function getFormData() {
  const fields = Object.entries(store.fields).reduce((acc, [key, value]) => {
    if (!uiFields.includes(key)) {
      acc[key] = value;
    }

    return acc;
  }, {});

  return fields;
}

export default store;
export { getFormData };
