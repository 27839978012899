<template>
  <div class="wrapper">
    <input
      type="radio"
      :name="name"
      :id="id"
      :value="fieldName"
      @change="$emit('input', $event.target.value)"
      :required="required"
    />
    <label :for="id">{{ label }} <span v-if="required">*</span></label>
  </div>
</template>

<script>
export default {
  name: "FieldRadio",
  inject: ["store"],
  props: {
    name: String,
    fieldName: String,
    id: String,
    label: String,
    required: Boolean,
    value: String
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

input {
  margin-right: 0.5rem;
}

label {
  font-weight: bold;
}
</style>
