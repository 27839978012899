import Vue from "vue";
import VueRouter from "vue-router";
import Form from "../components/Form.vue";
import Success from "../components/Success.vue";
import Error from "../components/Error.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/success",
    component: Success
  },
  {
    path: "/error",
    component: Error
  },
  {
    path: "/:step",
    component: Form
  },
  {
    path: "/",
    component: Form,
    redirect: "/1"
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  store.currentStep = to.params.step ? parseInt(to.params.step, 10) : 0;

  next();
});

export default router;
