<template>
  <div class="success">
    <h2 class="title">{{ title }}</h2>
    <p class="text" v-html="text" />
  </div>
</template>

<script>
export default {
  name: "Success",
  inject: ["config", "store"],
  data() {
    return {
      title: this.config.labels.successTitle,
      text: this.store.success || this.config.labels.success
    };
  }
};
</script>

<style scoped>
.title {
  margin-top: 0;
  margin-bottom: 3rem;
  font-size: 1.875rem;
  font-weight: 200;
}
</style>
