<template>
  <div class="field" v-if="isVisible(condition)">
    <fieldset v-if="type === 'radiobuttons'">
      <legend>{{ label }} <span v-if="required">*</span></legend>
      <div v-for="option in options" :key="option.name">
        <FieldRadio
          v-bind="{
            name,
            fieldName: option.name,
            id: `${name}-${option.name}`,
            required,
            label: option.label
          }"
          v-model="store.fields[name]"
        />
      </div>
    </fieldset>
    <div v-else-if="type === 'repeatable'">
      <div class="repeatable" v-for="(group, i) in store.fields[name]" :key="i">
        <fieldset>
          <legend v-if="label">
            {{ label }} ({{ i + 1 }}) <span v-if="required">*</span>
          </legend>
          <div v-for="field in fields" :key="field.name">
            <!-- <Field v-bind="field" v-model:value="store.fields[name][i][field.name]" /> -->
            <div class="field" v-if="isVisible(field.condition)">
              <div v-if="field.type === 'select'">
                <FieldSelect
                  v-bind="field"
                  v-model="store.fields[name][i][field.name]"
                />
              </div>
              <div v-else>
                <FieldInput
                  v-bind="field"
                  v-model="store.fields[name][i][field.name]"
                />
              </div>
            </div>
          </div>
        </fieldset>

        <button
          type="button"
          class="action action--remove"
          v-if="i > 0"
          @click="remove(i)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
          {{ removeLabel }}
        </button>
      </div>

      <button
        type="button"
        class="action action--add"
        @click="add()"
        :disabled="limitReached"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
        {{ addLabel }}
      </button>
      <p v-if="limitLabel">{{ limitLabel }}</p>
    </div>
    <div v-else-if="type === 'select'">
      <FieldSelect v-bind="$props" v-model="store.fields[name]" />
    </div>
    <div v-else>
      <FieldInput v-bind="$props" v-model="store.fields[name]" />
    </div>
  </div>
</template>

<script>
import FieldInput from "./FieldInput.vue";
import FieldRadio from "./FieldRadio.vue";
import FieldSelect from "./FieldSelect.vue";

export default {
  name: "Field",
  inject: ["store"],
  components: { FieldInput, FieldRadio, FieldSelect },
  props: {
    label: String,
    name: String,
    required: Boolean,
    type: String,
    condition: Function,
    options: Array,
    fields: Array,
    addLabel: String,
    removeLabel: String,
    limitLabel: String,
    hint: [String, Function],
    limit: Number,
    maxLength: Number,
    pattern: String,
    patternHint: String
  },
  computed: {
    limitReached() {
      const limitReached =
        this.limit && this.store.fields[this.name].length === this.limit;

      return limitReached;
    }
  },
  methods: {
    isVisible(condition) {
      if (!condition) {
        return true;
      }

      const isVisible = condition(this.store.fields);

      return isVisible;
    },
    add() {
      this.store.fields[this.name].push({});
    },
    remove(index) {
      this.store.fields[this.name].splice(index, 1);
    }
  }
};
</script>

<style scoped>
.field {
  margin-bottom: 1.5rem;
}

.repeatable {
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid black;
}

.action {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.25rem;
  background: #a9c938;
  color: #fff;
  font: inherit;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.action[disabled] {
  opacity: 0.2;
  cursor: not-allowed;
}

.action svg {
  fill: currentColor;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}

.action--add {
  background: #575757;
}
.action--remove {
  color: inherit;
  background: #f5f7f8;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
</style>
