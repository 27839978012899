<template>
  <div class="select">
    <label :for="name">{{ label }} <span v-if="required">*</span></label>
    <div class="select_container">
      <select
        :name="name"
        :id="name"
        :value="value"
        @change="$emit('input', $event.target.value)"
        :required="required"
        :aria-describedby="hintText ? `hint-${name}` : ''"
      >
        <option value="">{{ config.labels.pleaseSelect }}</option>
        <option
          v-for="option in options"
          :key="option.name"
          :value="option.name"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <p class="hint" v-if="hintText" :id="`hint-${name}`">{{ hintText }}</p>
  </div>
</template>

<script>
export default {
  name: "FieldSelect",
  inject: ["config", "store"],
  props: {
    name: String,
    label: String,
    required: Boolean,
    options: Array,
    value: String,
    hint: [String, Function]
  },
  computed: {
    hintText() {
      if (typeof this.hint === "function") {
        return this.hint(this.store.fields);
      }

      return this.hint;
    }
  }
};
</script>

<style scoped>
label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

select {
  font: inherit;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  background: #f5f7f8;
  padding: 0.75rem;
}

.hint {
  margin-top: 0.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
</style>
