<template>
  <div class="steps">
    <ul>
      <li v-for="(step, i) in steps" :key="i" :aria-current="isCurrent(i)">
        <span class="button">
          {{ step.titleShort || step.title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: ["steps"],
  inject: ["store"],
  methods: {
    isCurrent(index) {
      const isCurrent = this.store.currentStep === index + 1;

      return isCurrent;
    }
  }
};
</script>

<style scoped>
.steps {
  margin-bottom: 4rem;
  border-radius: 0.25rem;
  overflow: hidden;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  flex-grow: 1;
}
li:not(:first-child) {
  margin-left: 1px;
}

.button {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  border-top: 0.5rem solid #a9c938;
  background: #f5f7f8;
  text-decoration: none;
  color: inherit;
  text-transform: uppercase;
  font-weight: bold;
}

li[aria-current="true"] ~ li .button {
  font-weight: normal;
  border-top-color: #575757;
}
</style>
