<template>
  <div class="error">
    <h2 class="title">{{ title }}</h2>
    <p class="text" v-html="text" />
    <pre v-if="!isKnownError && (formData || errorInternal)"
      >{{ errorInternal }}
{{ formData }}
    </pre>
    <Actions
      v-if="isKnownError"
      :error="true"
      :errorLabel="config.labels.back"
    />
  </div>
</template>

<script>
import Actions from "./Actions.vue";
import { getFormData } from "../store";

export default {
  name: "Error",
  components: {
    Actions
  },
  inject: ["config", "store"],
  data() {
    const isKnownError = !!this.store.error;
    const formData = getFormData();

    return {
      isKnownError,
      title: this.config.labels.errorTitle,
      text: this.store.error || this.config.labels.error,
      errorInternal: this.store.errorInternal,
      formData: JSON.stringify(formData, null, "  ")
    };
  }
};
</script>

<style scoped>
.title {
  margin-top: 0;
  margin-bottom: 3rem;
  font-size: 1.875rem;
  font-weight: 200;
  color: #c30;
}
</style>
