const countries = require("i18n-iso-countries");

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countryOptions = Object.entries(
  countries.getNames("en", { select: "official" })
).map(([name, label]) => ({
  name,
  label
}));
const euCountryCodes = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  // "DE",
  "DK",
  "EE",
  "EL",
  "ES",
  "FI",
  "FR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK"
  // "UK" // until end of 2020
];

const config = {
  title: "Form",
  api: "https://api.exficon.davisol.cloud/api/ext/booking/submit",
  // api: "/mock-success.json",
  // api: "/mock-success-warning.json",
  // api: "/mock-error-invalid.json",
  // api: "/mock-error-halt.json",
  steps: [
    {
      title: "Customer / billing data",
      titleShort: "Customer",
      navigation: "Customer",
      fields: [
        {
          name: "company",
          type: "text",
          label: "Company / Institution",
          required: false
        },
        {
          name: "department",
          type: "text",
          label: "Department number / name",
          required: false
        },
        {
          name: "lastName",
          type: "text",
          label: "Last Name, Contact person",
          required: true
        },
        {
          name: "firstName",
          type: "text",
          label: "First Name, Contact person",
          required: true
        },
        {
          name: "street",
          type: "text",
          label: "Street, Number",
          required: true
        },
        {
          name: "street2",
          type: "text",
          label: "Street, Number (line 2, optional)"
        },
        { name: "zip", type: "text", label: "ZIP code", required: true },
        { name: "city", type: "text", label: "City", required: true },
        {
          name: "country",
          type: "select",
          label: "Country",
          required: true,
          options: countryOptions,
          hint: data => {
            if (data["country"] === "DE") {
              return "VAT liability at 19% at present";
            } else if (
              data["country"] &&
              !euCountryCodes.includes(data["country"])
            ) {
              return "No value-added tax will be applied.";
            }

            return null;
          }
        },
        {
          name: "vat",
          type: "text",
          label: "VAT number",
          options: countryOptions,
          condition: data => euCountryCodes.includes(data["country"]),
          hint: data => {
            if (!data["vat"]) {
              return "As long as no VAT number is applied, the German value-added tax will be applied.";
            }

            return null;
          }
        },
        { name: "email", type: "email", label: "E-mail", required: true },
        {
          name: "phone",
          type: "tel",
          label: "Phone number",
          required: true,
          hint: "Please include country and area code"
        },
        {
          name: "role",
          type: "select",
          label: "Role",
          required: true,
          options: [
            { name: "kfw", label: "Employee of KfW" },
            { name: "tenderAgent", label: "Tender Agent" },
            { name: "consultant", label: "(Implementation) Consultant" },
            { name: "agency", label: "Project Executing Agency" }
          ]
        }
      ]
    },
    {
      title: "Details",
      navigation: "Details",
      fields: [
        {
          name: "projectCountry",
          type: "select",
          label: "Project country",
          required: true,
          options: countryOptions
        },
        {
          name: "bmzTenderNumber",
          type: "text",
          label: "BMZ or Tender number",
          required: true
        },
        {
          name: "projectTitle",
          type: "text",
          label: "Project short title (abbreviation)",
          required: true,
          maxLength: 50,
          hint: `Maximum 50 characters`
        },
        {
          name: "processLanguage",
          type: "select",
          label: "Language of tender process",
          required: true,
          options: [
            { name: "German", label: "German" },
            { name: "English", label: "English" },
            { name: "French", label: "French" },
            { name: "Spanish", label: "Spanish" },
            { name: "Portuguese", label: "Portuguese" },
            { name: "Brazilian", label: "Brazilian" }
          ]
        },
        {
          name: "submissionDeadlineFixed",
          type: "radiobuttons",
          label: "Is the delivery date already fixed?",
          required: true,
          skipSubmission: true,
          options: [
            { name: "true", label: "Yes, delivery date is already defined" },
            { name: "false", label: "No, delivery date is not yet fixed" }
          ]
        },
        {
          name: "submissionDeadlineDate",
          type: "date",
          label: "Submission deadline, date",
          required: true,
          condition: data => data["submissionDeadlineFixed"] === "true",
          pattern: "\\d{4}-\\d{2}-\\d{2}",
          patternHint: "Format: YYYY-MM-DD"
        },
        {
          name: "submissionDeadlineTime",
          type: "time",
          label: "Submission deadline, time",
          required: true,
          condition: data => data["submissionDeadlineFixed"] === "true",
          hint:
            "Please confirm that the Submission Deadline has been stated as CET time",
          hintAdditional:
            "Please note that technical support is available Monday through Friday, from 9am to 5pm CET.",
          pattern: "\\d{2}:\\d{2}",
          patternHint: "Format: HH:MM"
        },
        {
          name: "tenderType",
          type: "select",
          label: "Tender type",
          required: true,
          options: [
            {
              name: "prequalification",
              label: "Prequalification („1 envelope“)"
            },
            {
              name: "solicitation1",
              label: "Solicitation of Bids („1 envelope“)"
            },
            { name: "tenderPhase", label: "Tender Phase („2 envelopes“)" },
            {
              name: "solicitation2",
              label: "Solicitation of Bids („2 envelopes“)"
            },
            {
              name: "packageTenderPhase",
              label: "Package PQ & Tender Phase (“3 envelopes”)"
            },
            {
              name: "postqualification",
              label: "Postqualification (“3 envelopes”)"
            }
          ]
        }
      ]
    },
    {
      title: "Downloader",
      navigation: "Downloader",
      fields: [
        {
          name: "downloadersDefined",
          type: "radiobuttons",
          label:
            "Are the persons authorized to access the download already defined?",
          required: true,
          skipSubmission: true,
          options: [
            { name: "true", label: "Yes, they are already defined" },
            { name: "false", label: "No, they are not yet fixed" }
          ]
        },
        {
          name: "downloaders",
          type: "repeatable",
          addLabel: "Add downloader",
          removeLabel: "Remove downloader",
          limitLabel: "Maximum 6 downloaders",
          limit: 6,
          condition: data => data["downloadersDefined"] === "true",
          fields: [
            {
              name: "role",
              type: "select",
              label: "Role",
              required: true,
              options: [
                { name: "kfw", label: "KfW" },
                {
                  name: "tenderAgent",
                  label: "Tender Agent / (Implementation) Consultant"
                },
                {
                  name: "evaluationCommittee",
                  label: "Representative of evaluation committee"
                }
              ]
            },
            {
              name: "company",
              type: "text",
              label: "Company / Institution",
              required: true
            },
            {
              name: "lastName",
              type: "text",
              label: "Last Name, Contact person",
              required: true
            },
            {
              name: "firstName",
              type: "text",
              label: "First Name, Contact person",
              required: true
            },
            { name: "email", type: "email", label: "E-mail", required: true },
            {
              name: "phone",
              type: "tel",
              label: "Phone number",
              required: false
            }
          ]
        }
      ]
    },
    {
      title: "Uploader",
      navigation: "Uploader",
      fields: [
        {
          name: "uploadersDefined",
          type: "radiobuttons",
          label:
            "Are the persons authorized to access the upload already defined?",
          required: true,
          skipSubmission: true,
          options: [
            { name: "true", label: "Yes, they are already defined" },
            { name: "false", label: "No, they are not yet fixed" }
          ]
        },
        {
          name: "uploaders",
          type: "repeatable",
          addLabel: "Add uploader",
          removeLabel: "Remove uploader",
          condition: data => data["uploadersDefined"] === "true",
          fields: [
            {
              name: "role",
              type: "select",
              label: "Role",
              required: false,
              options: [
                { name: "kfw", label: "KfW" },
                {
                  name: "tenderAgent",
                  label: "Tender Agent / (Implementation) Consultant"
                },
                {
                  name: "evaluationCommittee",
                  label: "Representative of evaluation committee"
                }
              ]
            },
            {
              name: "company",
              type: "text",
              label: "Company / Institution",
              required: true
            },
            {
              name: "lastName",
              type: "text",
              label: "Last Name, Contact person",
              required: true
            },
            {
              name: "firstName",
              type: "text",
              label: "First Name, Contact person",
              required: true
            },
            { name: "email", type: "email", label: "E-mail", required: true },
            {
              name: "phone",
              type: "tel",
              label: "Phone number",
              required: false
            }
          ]
        }
      ]
    },
    {
      title: "Payment",
      navigation: "Payment",
      fields: [
        {
          name: "paymentMethod",
          type: "select",
          label: "Payment method",
          required: true,
          options: [
            { name: "invoice", label: "Invoice" },
            { name: "prepaid", label: "Prepaid" }
          ]
        }
      ]
    }
  ],
  labels: {
    pleaseSelect: "Please select",
    successTitle: "Thank you",
    success: "We have received your submission.",
    errorTitle: "Something went wrong",
    error: `Please contact us via <a href="mailto:e-procurement@exficon.de">e-procurement@exficon.de</a>, we are very sorry for the inconvenience. Copying the following data into the e-mail will help us a lot. Thanks in advance!`,
    nextStep: "Next step",
    previousStep: "Previous step",
    submit: "Submit",
    stepNotFound: "Step not found.",
    toOverview: "Go to overview",
    back: "Go back to form"
  }
};

export default config;
