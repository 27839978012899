<template>
  <div class="input">
    <label :for="name">{{ label }} <span v-if="required">*</span></label>
    <input
      :type="type"
      :name="name"
      :id="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @invalid="onInvalid"
      @blur="onBlur"
      :required="required"
      :aria-describedby="hintText ? `hint-${name}` : ''"
      :maxlength="maxLength"
      :pattern="pattern"
      :title="error"
      ref="input"
    />
    <p
      class="hint"
      :class="{ 'hint--error': !!error }"
      v-if="hintText"
      :id="`hint-${name}`"
      v-html="hintText"
    ></p>
  </div>
</template>

<script>
export default {
  name: "FieldInput",
  inject: ["store"],
  data() {
    return {
      error: "",
      hintPrefix: ""
    };
  },
  props: {
    name: String,
    label: String,
    required: Boolean,
    type: String,
    value: String,
    hint: [String, Function],
    maxLength: {
      type: Number,
      default() {
        return 100;
      }
    },
    pattern: String,
    patternHint: String
  },
  computed: {
    hintText() {
      if (this.error) {
        return this.error;
      }

      let { hint } = this;

      if (typeof this.hint === "function") {
        hint = this.hint(this.store.fields);
      }

      // Optionally prefix with expected input pattern, e.g.
      hint = `${this.hintPrefix ? `${this.hintPrefix}<br>` : ""}${
        hint ? hint : ""
      }`;

      return hint;
    }
  },
  mounted() {
    // Detect unsupported input type, show expected input pattern
    if (this.$refs.input.type !== this.type) {
      this.hintPrefix = this.patternHint;
    }
  },
  methods: {
    onInvalid(event) {
      const { value } = event.target;

      // Show error with expected input pattern if specified
      if (value) {
        this.error = this.patternHint;
      }
    },
    onBlur(event) {
      const { validity } = event.target;

      // Remove error if input is valid
      if (validity.valid) {
        this.error = "";

        return true;
      }
    }
  }
};
</script>

<style scoped>
label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input {
  font: inherit;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  background: #f5f7f8;
  padding: 0.75rem;
}

.hint {
  margin-top: 0.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.hint--error {
  color: #c30;
}
</style>
